.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #64738A;
    opacity: 1;

}

.swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    background: #D9D9D9;
    opacity: 1;
}

.swiper-pagination-bullets {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-wrapper {
    align-items: center;
}